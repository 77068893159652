import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { Observable, Subscription, debounceTime, fromEvent, merge, startWith, tap } from 'rxjs';
import { UserTypes } from '../../models/global';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SessionExpiringDialogComponent } from '../../components/dialogs/session-expiring-dialog/session-expiring-dialog.component';
import { AppStateService } from '../app-state/app-state.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class IdleService {

  private worker = new Worker(new URL('../../../app.worker', import.meta.url));
  private inactivityTimerEvents: Array<any>[] = [
    [document, 'click'],
    [document, 'wheel'],
    [document, 'scroll'],
    [document, 'mousemove'],
    [document, 'keyup'],
    [window, 'click'],
    [window, 'resize'],
    [window, 'scroll'],
    [window, 'mousemove'],
    [window, 'touchend'],
    [window, 'touchmove'],
  ];
  eventObservables$: Observable<any>;
  private subscription: Subscription;
  inactivityTime: number;
  private expiryDialogRef: MatDialogRef<SessionExpiringDialogComponent>;

  constructor(
    public _ngZone: NgZone,
    public _ref: ApplicationRef,
    private dialog: MatDialog,
    private appState: AppStateService,
    private router: Router,
  ) {
    let observableArray$: Observable<any>[] = [];
    this.inactivityTimerEvents.forEach(x => {
      observableArray$.push(fromEvent(x[0], x[1]))
    });
    this.eventObservables$ = merge(...observableArray$);


    if (typeof Worker !== 'undefined') {
      this.worker.onmessage = this.processWorkerMessage.bind(this);
    } else {
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }
  }

  startTimer(role: UserTypes): void {

    switch (role) {
      case UserTypes.FACILITY_ADMIN:
        this.inactivityTime = 7200;
        break;
      case UserTypes.FACILITY_USER:
        return;
        // this.inactivityTime = 0;
        // break;
      case UserTypes.SUPER_ADMIN:
        this.inactivityTime = 7200;
        break;
    }

    this._ngZone.runOutsideAngular(() => {

      this.subscription = this.eventObservables$
        .pipe(
          startWith(true),
          // try repond to less events (especially mousemoves)
          debounceTime(50),
          // tap(val => console.log('[IDLE] Restart timer')),
          tap(val => {
            this.worker.postMessage({ action: 'RESTART', timeout: this.inactivityTime });
          }),
        ).subscribe();
    });
  }

  private processWorkerMessage(data: { data: any }) {

    if (data.data === 'EXPIRING') {
      if (!this.expiryDialogRef) {
        const collection = this.appState.contentItemCollection['expiry-dialog'];
        this.expiryDialogRef = this.dialog.open(SessionExpiringDialogComponent, {
          panelClass: 'default-dialog',
          backdropClass: 'apollo-backdrop',
          data: {
            heading: collection.heading,
            body: collection.body,
          }
        });

        let sessionTimeoutId: any;
        const timeoutSubscription = this.expiryDialogRef.afterOpened().subscribe(val => {
          sessionTimeoutId = setTimeout(() => {
            this.expiryDialogRef.close();
            this.expiryDialogRef = null;
            timeoutSubscription.unsubscribe();
            this.stopTimer();
            this.router.navigate(['auth/do-logout']);
          }, 30000);
        });

        const subscription = this.expiryDialogRef.afterClosed()
          .subscribe(val => {
            this.expiryDialogRef = null;
            subscription.unsubscribe();
            if (sessionTimeoutId) {
              clearTimeout(sessionTimeoutId);
              sessionTimeoutId = null;
            }
          });
      }
    }
    // if (data.data === 'PING') {
      // console.log(`[pong] ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`);
    // }
  }

  stopTimer() {
    this.subscription.unsubscribe();
    this.subscription = null;
    this.worker.postMessage({ action: 'STOP' });
  }
}